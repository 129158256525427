import React from 'react'
import { css } from '@emotion/core'
import Layout from '../components/layout'
import { CenterTextDiv, VerticalSpace } from '../components/common'

const Kontakt = () => {
  return (
    <Layout>
      <CenterTextDiv>
        <h2>Namasté!</h2>
        <p>
          Wir freuen uns, Dich bald bei uns begrüßen zu können. Falls du Fragen
          hast, kannst Du uns jederzeit telefonisch oder auch per Mail
          erreichen.
        </p>
      </CenterTextDiv>
      <iframe
        title="Yogastudio Olga Hilz Karte"
        width="100%"
        height="400"
        frameBorder="0"
        style={{ border: 0 }}
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJK1ocdS1xsUcRBMLDhsZ-Qfc&key=AIzaSyBp6TYWa9mGTJ9BnJH56VLkOVHmJiueJG8"
        allowFullScreen
      ></iframe>
      <VerticalSpace />
      <div
        css={css`
          display: flex;

          & * {
            flex: 1;
          }

          @media (max-width: 720px) {
            display: inherit;
          }
        `}
      >
        <CenterTextDiv>
          <p>
            <strong>Jederzeit erreichbar unter:</strong>
          </p>
          <p>
            M: <a href="tel:+49 15774120416">0 157 741 204 16</a> <br />
            T: <a href="tel:+49 41 64 897 122">041 64 897 122</a> <br />
            Mail: <a href="mailto:o.hilz@freenet.de">o.hilz@freenet.de</a>
          </p>
        </CenterTextDiv>

        <CenterTextDiv>
          <p>
            <strong>Hier machen wir Yoga:</strong>
          </p>
          <p>
            Martenskamp 16
            <br />
            21698 Harsefeld
          </p>
        </CenterTextDiv>

        <CenterTextDiv>
          <p>
            <strong>Für Dich geöffnet:</strong>
          </p>
          <p>
            Unser Studio ist 20 Minuten vor und kurz nach dem Unterricht
            geöffnet. Während den Kursen sind die Türen geschlossen.
          </p>
        </CenterTextDiv>
      </div>
    </Layout>
  )
}

export default Kontakt
